import React, {useState} from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/modal"
import SeaContact from "../components/sea-contact"

const SouthEastAsiaPage = () => {

  const [showModal, setShowModal] = useState(false)

  return (
    <Layout>
      <Seo title="South East Asia Bee - 2022" />
      <div className="seaHero seaSection">
        <div className="container txt-c">
          <h1 className="seaHero_title h0 mb-0">
            <span className="h-light txt-large">South East Asia</span><br/>
            <span className="seaHero_title_main">Writing Bee</span>
          </h1>
          <h2 className="seaHero_byline h-script h1">Because all kids like writing stories</h2>

          <ul className="seaHero_bp h-script h2">
            <li className="seaHero_bp_item">Standard-based writing practice</li>
            <li className="seaHero_bp_item">Online testing preparation</li>
            <li className="seaHero_bp_item">Personalised writing feedback</li>
            <li className="seaHero_bp_item">Transferable writing skills</li>
          </ul>

          <h3 className="h-light">23 May - 1 Sept 2022</h3>
        </div>
      </div>
      <div className="container seaCountries seaSection txt-c">
        <h2 className="h0 txt-c sea-h">Take Part</h2>
        <div className="seaCountries_flags">
          <div>
            <StaticImage src="../images/sea/australia.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/cambodia.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/hong-kong.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/indonesia.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/malaysia.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/myanmar.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/philipinnes.png" alt="Australia" width={140} />
          </div>
          <div>
            <StaticImage src="../images/sea/singapore.png" alt="Australia" width={140} />
          </div>
        </div>
        <p>The South East Asia Writing Bee is open to schools in the following countries:</p>
        <ul className="seaCountries_list">
          <li>Australia</li>
          <li>Cambodia</li>
          <li>Hong Kong</li>
          <li>Indonesia</li>
          <li>Malaysia</li>
          <li>Myanmmar</li>
          <li>Philippines</li>
          <li>Singapore</li>
        </ul>
        <button className="btn-sea" onClick={() => setShowModal(true)}>Register Interest</button>
      </div>
      <div className="seaSection container txt-c">
        <div className="txt-block-c">
          <h3 className="h0 h-script sea-h">A global event that starts right in your classroom</h3>
          <p>The Writing Bee Qualifying Rounds take place in individual classrooms. Classroom-based winners and top vote-getters qualify for the Writing Bee Finals and a chance to become The Writing Bee Champion!</p>
        </div>
      </div>

      <div className="seaSection seaHowWorks">
        <div className="container">
          <div className="grid">
            <div className="grid_s8_c txt-c mb-2">
              <h3 className="h0 seaHowWorks_title">How It Works</h3>
              <p>The Writing Bee kicks off in schools with students participating in district or school-based Qualifying sessions. Students who qualify move on to the Finals with a chance to be crowned The Writing Bee Champion.</p>
              <StaticImage src="../images/home/how-it-works.png" alt="Writing Bee Competition Process" width={700} />
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>1. Kick Off</h4>
              <p>Students read the Writing Bee's original story start before independently writing their version of the next chapter. Teachers have the option to provide feedback and request revisions before approving each student's work.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>2. Qualifying Rounds</h4>
              <p>Students read the anonymous submissions of their peers in small batches and vote for their favorites. A winning piece is identified to serve as the story's middle chapter, and the process repeats to identify the final chapter.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>3. Finals</h4>
              <p>Class chapter winners and top vote-getters qualify to compete in The Writing Bee Finals. Plus, all classroom Qualifying round participants are eligible to read and cast votes for their favorites throughout The Finals.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>4. Winner</h4>
              <p>Writing Bee Finalists create a new story by submitting their work for review by students around the world. Only top vote-getters move on in this 'knockout style' event until the Writing Bee Champion is crowned!</p>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal id="Register" closeModal={() => setShowModal(false)}>
          <SeaContact></SeaContact>
        </Modal>
      )}
    </Layout>
  )
}

export default SouthEastAsiaPage