import React from "react"
import { X } from "react-feather"

const Modal = ({children, closeModal}) => {

    return(
        <>
        <section className="modal">
            <div className="modalBlanker">
                <div className="modalContent">
                    <button className="modalCloseButton" aria-label="close">
                        <X className="reversed" onClick={() => closeModal()} size="32"/>
                    </button>
                    <div className="modalContentHolder">
                        <div className="modalContentInner">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Modal