import React from "react"

const SeaContact = () => {
  return (
    <form
      method="post"
      className="af-form-wrapper"
      acceptCharset="UTF-8"
      action="https://www.aweber.com/scripts/addlead.pl"
    >
      
      <div style={{display: "none"}}>
        <input type="hidden" name="meta_web_form_id" defaultValue="345583940" />
        <input type="hidden" name="meta_split_id" defaultValue="" />
        <input type="hidden" name="listname" defaultValue="awlist6275858" />
        <input
          type="hidden"
          name="redirect"
          defaultValue="https://www.aweber.com/thankyou-coi.htm?m=text"
          id="redirect_2fc22c860857420fa7d5ab6612d26432"
        />

        <input
          type="hidden"
          name="meta_adtracking"
          defaultValue="WritingBee_Contact_Form"
        />
        <input type="hidden" name="meta_message" defaultValue="1" />
        <input
          type="hidden"
          name="meta_required"
          defaultValue="name (awf_first),name (awf_last),email"
        />

        <input
          type="hidden"
          name="meta_tooltip"
          defaultValue="custom Country||Pick a country"
        />
      </div>
      <div id="af-form-345583940" className="af-form">
        <div id="af-body-345583940" className="af-body af-standards">
          <h2 className="h2 h-script sea-h txt-c">Please tell me more about the Writing Bee:</h2>
          <div className="af-element">
            <label className="previewLabel" htmlFor="awf_field-114165183-first">
              First Name:
            </label>
            <input
              id="awf_field-114165183-first"
              type="text"
              className="text"
              name="name (awf_first)"
              defaultValue=""
            />
          </div>
          <div className="af-element">
            <label className="previewLabel" htmlFor="awf_field-114165183-last">
              Last Name:
            </label>
            <input
              id="awf_field-114165183-last"
              className="text"
              type="text"
              name="name (awf_last)"
              defaultValue=""
            />
          </div>
          <div className="af-element">
            <label className="previewLabel" htmlFor="awf_field-114165184">
              Email:{" "}
            </label>
            <input
              className="text"
              id="awf_field-114165184"
              type="text"
              name="email"
              defaultValue=""
            />
          </div>
          <div className="af-element">
            <label className="previewLabel" htmlFor="awf_field-114165185">
              Country:
            </label>
            <select
              id="awf_field-114165185"
              name="custom Country"
              defaultValue="preSelectedHeadingSelected"
            >
              <option
                className="multiChoice"
                value="preSelectedHeadingSelected"
              >
                Pick a country
              </option>
              <option className="multiChoice" value="Australia">
                Australia
              </option>
              <option className="multiChoice" value="Cambodia">
                Cambodia
              </option>
              <option className="multiChoice" value="Hong Kong">
                Hong Kong
              </option>
              <option className="multiChoice" value="Indonesia">
                Indonesia
              </option>
              <option className="multiChoice" value="Malaysia">
                Malaysia
              </option>
              <option class="multiChoice" value="Burma">
                Myanmmar
              </option>
              <option className="multiChoice" value="Philippines">
                Philippines
              </option>
              <option className="multiChoice" value="Singapore">
                Singapore
              </option>
            </select>
            <input
              type="hidden"
              name="meta_preselected_awf_field-114165185"
              defaultValue=""
            />
          </div>
          <div className="af-element buttonContainer">
            <button type="submit" className="btn-sea">Register Interest</button>
          </div>
          <p className="txt-small txt-c">
            We respect your{" "}
            <a title="Privacy Policy" href="https://www.aweber.com/permission.htm" target="_blank" rel="noopener nofollow">
              email privacy
            </a>
          </p>
          <p className="txt-small txt-c">
            <a href="https://www.aweber.com" title="AWeber Email Marketing" target="_blank" rel="noopener nofollow">
              Powered by AWeber Email Marketing
            </a>
          </p>
        </div>
      </div>
      <div style={{display: "none"}}>
        <img src="https://forms.aweber.com/form/displays.htm?id=zCysrBzMnCwM" alt="" />
      </div>
    </form>
  )
}

export default SeaContact
